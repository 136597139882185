import React from "react";
import {
  UilCreateDashboard,
  UilFileShieldAlt,
  UilSetting,
  UilUsersAlt,
  UilMoneyWithdraw,
  UilPalette,
  UilStoreAlt,
  UilBrushAlt,
  UilEllipsisV,
  UilPaintTool,
  UilMoneyStack,
  UilHistory,
  UilEnvelopes
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import propTypes from "prop-types";
import {
  changeDirectionMode,
  changeLayoutMode,
  changeMenuMode,
} from "../redux/themeLayout/actionCreator";

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = "/admin";

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : "";
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector("html");
    if (topMode) {
      html.classList.add("ninjadash-topmenu");
    } else {
      html.classList.remove("ninjadash-topmenu");
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector("html");
      html.setAttribute("dir", "rtl");
    } else {
      const html = document.querySelector("html");
      html.setAttribute("dir", "ltr");
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add("dark-mode");
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove("dark-mode");
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t("dashboard")}
      </NavLink>,
      "dashboard",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilCreateDashboard />
        </NavLink>
      )
    ),
    
    getItem(t("Paintings"), "primarymarket", !topMenu && <UilPaintTool />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/paintings`}>
          {t("All Paintings")}
        </NavLink>,
        "painting",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/unlisted-paintings`}>
          {t("Unlisted Paintings")}
        </NavLink>,
        "unlisted",
        null
      ),
    ]),
    
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/primary-market`}>
        {t("Primary Market")}
      </NavLink>,
      "primary-market",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/primary-market`}>
          <UilStoreAlt />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/secondary-market`}>
        {t("Secondary Market")}
      </NavLink>,
      "secondary market",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/secondary-market`}>
          <UilStoreAlt />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dividend`}>
        {t("Dividend")}
      </NavLink>,
      "dividend",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dividend`}>
          <UilMoneyStack />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
        {t("Users")}
      </NavLink>,
      "users",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/users`}>
          <UilUsersAlt />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/artists`}>
        {t("Artists")}
      </NavLink>,
      "artists",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/artists`}>
          <UilBrushAlt />
        </NavLink>
      )
    ),

    getItem(t("Withdrawals"), "withdrawals", !topMenu && <UilMoneyWithdraw />, [
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/payout/pending-payouts`}
        >
          {t("Pending")}
        </NavLink>,
        "pending-payouts",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/payout/paid-payouts`}>
          {t("Paid")}
        </NavLink>,
        "paid-payouts",
        null
      ),
    ]),

    getItem(t("CMS"), "cms", !topMenu && <UilFileShieldAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/manage-home`}>
          {t("Manage Home")}
        </NavLink>,
        "manage-home",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/gallery`}>
          {t("Gallery")}
        </NavLink>,
        "gallery",
        null
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/cms/locations`}>
      //     {t("Locations")}
      //   </NavLink>,
      //   "locations",
      //   null
      // ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/privacy-policy`}>
          {t("Privacy Policy")}
        </NavLink>,
        "privacy-policy",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/terms`}>
          {t("Terms & Conditions")}
        </NavLink>,
        "terms",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/faqs`}>
          {t("FAQs")}
        </NavLink>,
        "faqs",
        null
      ),
    ]),

    getItem(t("Settings"), "settings", !topMenu && <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/edit-profile`}>
          {t("Edit Profile")}
        </NavLink>,
        "edit-profile",
        null
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/settings/change-password`}
        >
          {t("Change Password")}
        </NavLink>,
        "change-password",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/site-settings`}>
          {t("Site Settings")}
        </NavLink>,
        "site-settings",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/footer`}>
          {t("Footer")}
        </NavLink>,
        "footer",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/waitlist`}>
          {t("Waitlist")}
        </NavLink>,
        "waitlist",
        null
      ),
    ]),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/logs`}>
        {t("Logs")}
      </NavLink>,
      "logs",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/logs`}>
          <UilHistory />
        </NavLink>
      )
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
