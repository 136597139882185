import { apiHeader, apiNoHeader } from "./BaseUrl";
import moment from "moment";

// Auth - Login
export const loginUser = async (values) => {
  let data;
  await apiHeader
    .post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
      device_type: "web",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Logout
export const logoutUser = async () => {
  const refresh_token = localStorage.getItem('refresh_token') || {};
  let data;
  await apiHeader
    .post("/auth/logout", {
      refresh_token: refresh_token,
      device_token: "string"
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Forgot Password
export const forgotPassword = async (values) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd", {
      email: values.email,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
// Auth - Reset Password Page Redirection Api
export const resetPasswordPage = async (token, user_id) => {
  let data;
  await apiHeader
    .get(`/auth/forgotpwd/verify-link?forgot_pwd_token=${token}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

// Auth - Reset Password Api
export const resetPassword = async (values, token, apiData) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd/reset", {
      email: apiData.email,
      forgot_pwd_token: token,
      new_password: values.new_password,
      confirm_new_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Auth - Edit Profile Update Password
export const updatePassword = async (values) => {
  let data;
  await apiHeader
    .put("/auth/password/reset", {
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// User Profile Get Data 
export const getProfile = async () => {
  let data;
  await apiHeader
    .get("/user/profile")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// User Profile Edit Data
export const editProfile = async (values) => {
  let data;
  await apiHeader
    .patch("/user/profile", {
      first_name: values.first_name,
      last_name: values.last_name,
      slug: values.phone.slug,
      country_code: values.phone.code,
      number: values.phone.number,
      curr: values.curr
    })
    .then((res) => {

      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
    return data;
};

// User Profile Edit Photo Only
export const updateProfilePicture = async (image) => {
  let data;
  const apiUrl = "/user/profile-image";
  const formData = new FormData();
  formData.append("profile_picture", image);
  try {
    const res = await apiHeader.post(apiUrl, formData);
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }

  return data;
};

// Dashboard Card States
export const dashboardStates = async () => {
  let data;
  await apiHeader
    .get("/content/dashboard")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};



//Refresh Token
export const refreshAccessToken = async (refreshToken) => {
  let data;
  await apiNoHeader
    .post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Painting - Painting Listing - All / Unlisted / Primary / Secondary
export const paintingListing = async (limit, offset, painting_name, type, artist_id, paintingStatus, marketType) => {
  let data;
  let queryString = `/paintings?limit=${limit}&offset=${offset}&type=${type}`;
  if (painting_name) {
    queryString += `&painting_name=${painting_name}`;
  }
  if (artist_id) {
    queryString += `&artist_id=${artist_id}`;
  }
  if (marketType) {
    queryString += `&market_select=${marketType}`;
  }
  if (paintingStatus) {
    queryString += `&status_select=${paintingStatus}`;
  }

  await apiHeader
    .get(queryString)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Painting - Current - Shareholders
export const paintingShareHolders = async (limit, offset, painting_id) => {
  let data;
  await apiHeader
    .get(`/paintings/shareholders?limit=${limit}&offset=${offset}&painting_id=${painting_id}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Painting - Create Painting API
export const paintingCreate = async (values) => {
let data;
await apiHeader
  .post(`/paintings/create`,{
    title_en: values.painting_title_en,
    title_fr: values.painting_title_fr,
    price_dollar: values.painting_total_value_usd,
    price_euro: values.painting_total_value_euro,
    total_parts: values.total_parts,
    artist_id: values.artist_name,
    height: values.height,
    width: values.width,
    year: values.painting_year,
    description_en: values.description_en,
    description_fr: values.description_fr,
    cover_img: values.cover_img,
    images: values.images,
    evolution: values.evolution
  },
  ).then((res) => {
    data = {
      data: res.data,
      status: res.status,
    };
  })
  .catch((e) => {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  });
return data;
};

// Painting - Create Painting API
export const paintingUpdate = async (values, painting_id) => {
let data;
await apiHeader
  .patch(`/paintings/update`,{
    painting_id: painting_id,
    title_en: values.painting_title_en,
    title_fr: values.painting_title_fr,
    price_dollar: values.painting_total_value_usd,
    price_euro: values.painting_total_value_euro,
    total_parts: values.total_parts,
    artist_id: values.artist_name,
    height: values.height,
    width: values.width,
    year: values.painting_year,
    description_en: values.description_en,
    description_fr: values.description_fr,
    cover_img: values.cover_img,
    images: values.images,
    evolution: values.evolution
  }).then((res) => {
    data = {
      data: res.data,
      status: res.status,
    };
  })
  .catch((e) => {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  });
return data;
};


// Painting - Edit - Get Specific Painting Details
export const PaintingEdit = async (painting_id) => {
  let data;
  await apiHeader
    .get(`/paintings/edit?painting_id=${painting_id}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};



//  - Update Secondary Market Painting Price Only both (USD and in Euro)
export const paintingUpdateSec = async (values, painting_id) => {
  let data;
  await apiHeader
    .patch("/paintings/update/secondary-price", {
      painting_id: painting_id,
      dollar_per_part: values.parts_price_usd,
      euro_per_part: values.parts_price_euro,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Painting - (Primary - Current) - Purchase Parts for Admin
export const paintingPurchasePrim = async (values, painting_id) => {
  let data;
  await apiHeader
    .post("/paintings/purchase", {
      painting_id: painting_id,
      total_part: values.total_part,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Painting - (Primary - Unlisted)
export const paintingPublish = async (values, painting_id) => {
  let data;
  await apiHeader
    .patch("/paintings/publish", {
      painting_id: painting_id,
      end_date: values.end_date,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Dividend - Dividend Listing -
export const dividendListing = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/dividends?limit=${limit}&offset=${offset}&painting_name=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Dividend - details
export const dividendDetailListing = async ( dividend_id) => {
  let data;
  await apiHeader
    .get(`/dividends/detail?dividend_id=${dividend_id}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Dividend - create
//  Paiting - Painting Listing - All / Unlisted / Primary / Secondary
export const dividendCreate = async (painting_id, criteria, price_dollar, price_euro) => {
  let data;
  await apiHeader
    .get(`/dividends/create?painting_id=${painting_id}&criteria=${criteria}&price_dollar=${price_dollar}&price_euro=${price_euro}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Dividend Store
export const dividendStore = async (painting_id, criteria, price_dollar, price_euro) => {
  let data;
  await apiHeader
    .post(`/dividends/store`,{
      painting_id:painting_id,
      criteria:criteria,
      price_dollar:price_dollar,
      price_euro:price_euro
    })
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    },)
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Artist - Get Artists Lisiting 
export const getArtists = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/artists/?limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Artist - Get Artists Details
export const getArtistsDetails = async (artistId) => {
  let data;
  await apiHeader
    .get(`/artists/${artistId}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Artist - Edit Artists API
export const artistsEdit = async (values, id) => {
  let data;
  await apiHeader
    .put(`/artists/${id}`, {
      name: values.artist_name,
      dob: values.dob,
      dod: values.dod,
      is_dod: values.is_dod,
      description_en: values.description_en,
      description_fr: values.description_fr,
      attachment: values.image,
      evolution: values.evolution
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Artist - Create Artists API
export const artistsCreate = async (values) => {
  let data;
  await apiHeader
    .post(`/artists`, {
      name: values.artist_name,
      dob: values.dob,
      dod: values.dod,
      is_dod: values.is_dod,
      description_en: values.description_en,
      description_fr: values.description_fr,
      attachment: values.image,
      evolution: values.evolution
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Artist - Get Artist List Dropdown - Get Data API
export const artistNameListing = async () => {
  let data;
  await apiHeader
    .get(`/artists/data/list`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Users - View Users
export const usersListing = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/user?search=${search}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

  //  Users - View Users Details
  export const usersDetails = async (id) => {
    let data;
    await apiHeader
      .get(`/user/show/${id}`)
      .then((res) => {
        data = {
          data: res.data,
          status: res.status,
        };
      })
      .catch((e) => {
        if (e.response) {
          data = {
            ...e.response.data,
            status: e.response.status,
          };
        }
      });
    return data;
  };


  // Users - History - API
export const getUserHistory = async (userId, limit, offset, statusType) => {
  let data;
  await apiHeader
    .get(`/user/${userId}/history/${statusType}?limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Withdrawal - Bank Pending - Mark As Paid - Action Modal
export const bankPendingPayoutsPay = async (values, id) => {
  let data;
  await apiHeader
    .put(`/payouts/bank/${id}`, {
      transaction_id: values.reference_id,
    })
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Withdrawal - Paypal Pending - Pay via Paypal
export const paypalPendingPayoutsPay = async (id) => {
  let data;
  await apiHeader
    .put(`/payouts/paypal/${id}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Withdrawal - Bank Pending Withdrawal
export const bankPendingPayouts = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/payouts/bank/pending?search=${search}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Withdrawal - Paypal Pending Withdrawal
export const paypalPendingPayouts = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/payouts/paypal/pending?search=${search}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//  Withdrawal - Bank Paid Withdrawal
export const bankPaidPayouts = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/payouts/bank/paid?search=${search}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Withdrawal - Paypal Paid Withdrawal
export const paypalPaidPayouts = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/payouts/paypal/paid?search=${search}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// FAQS - Get Faqs
export const getFaqs = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/cms/faqs?limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// FAQs - Create FAQs API
export const FaqsCreate = async (values) => {
  let data;
  await apiHeader
    .post(`/cms/faqs`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      description_en: values.description_en,
      description_fr:values.description_fr,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// FAQs - Update FAQs API
export const FaqsUpdate = async (values,id) => {
  let data;
  await apiHeader
    .put(`/cms/faqs/${id}`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      description_en: values.description_en,
      description_fr:values.description_fr,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Delete Faqs
export const deleteFaqs = async (id) => {
  let data;
  await apiHeader
    .delete(`/cms/faqs/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Faqs Status Update
export const FaqsStatusUpdate = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/cms/faqs/${id}`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//Swap Order FAQS
export const faqSwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch("/cms/faqs/swap-order", {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Privacy Policy - Get Privacy Policy
export const getPrivacyPolicy = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`cms/privacy-policy?limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Create PrivacyPolicy API
export const PrivacyPolicyCreate = async (values) => {
  let data;
  await apiHeader
    .post(`cms/privacy-policy`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      description_en: values.description_en,
      description_fr:values.description_fr,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Privacy Policy - Update Privacy Policy API
export const PrivacyPolicyUpdate = async (values,id) => {
  let data;
  await apiHeader
    .put(`/cms/privacy-policy/${id}`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      description_en: values.description_en,
      description_fr:values.description_fr,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Delete PrivacyPolicy
export const deletePrivacyPolicy = async (id) => {
  let data;
  await apiHeader
    .delete(`/cms/privacy-policy/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Privacy Policy Status Update
export const PrivacyPolicyStatusUpdate = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/cms/privacy-policy/${id}`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//Swap Privacy Policy
export const PrivacyPolicySwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch("/cms/privacy-policy/swap-order", {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Get Terms & Conditions
export const getTermsCondition = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`cms/term-conditions?limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Create Terms & Conditions
export const TermsConditionCreate = async (values) => {
  let data;
  await apiHeader
    .post(`cms/term-conditions`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      description_en: values.description_en,
      description_fr:values.description_fr,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Terms & Conditions - Update Terms & Conditions API
export const TermsConditionUpdate = async (values,id) => {
  let data;
  await apiHeader
    .put(`/cms/term-conditions/${id}`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      description_en: values.description_en,
      description_fr:values.description_fr,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Delete Terms & Conditions
export const deleteTermsCondition = async (id) => {
  let data;
  await apiHeader
    .delete(`/cms/term-conditions/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Terms & Conditions Status Update
export const TermsConditionStatusUpdate = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/cms/term-conditions/${id}`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

export const TermsConditionSwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch("/cms/term-conditions/swap-order", {
      new_value: new_value,
      new_id: new_id, 
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Location - Get CMS Location
// export const getLocation = async (limit, offset, search) => {
//   let data;
//   await apiHeader
//     .get(`/locations?limit=${limit}&offset=${offset}&search=${search}`)
//     .then((res) => {
//       data = {
//         data: res.data,
//         status: res.status,
//       };
//     })
//     .catch((e) => {
//       if (e.response) {
//         data = {
//           ...e.response.data,
//           status: e.response.status,
//         };
//       }
//     });
//   return data;
// };
// //Location - Create CMS Location
// export const locationCreate = async (values,slug,countryCode, phoneNumber,imageResponse) => {
//   let data;
//   await apiHeader
//     .post(`/locations`, {
//       title_en: values.title_en,
//       title_fr: values.title_fr,
//       web_link: values.website,
//       longitude:values.longitude,
//       latitude:values.latitude,
//       img: imageResponse,
//       address:values.address,
//       slug:slug,
//       code:countryCode,
//       number:phoneNumber
//     }).then((res) => {
//       data = {
//         data: res.data,
//         status: res.status,
//       };
//     })
//     .catch((e) => {
//       if (e.response) {
//         data = {
//           ...e.response.data,
//           status: e.response.status,
//         };
//       }
//     });
//   return data;
// };

// //Location - Update CMS Location
// export const locationUpdate = async (values,slug,countryCode, phoneNumber,id,imageResponse) => {
//   let data;
//   await apiHeader
//     .put(`/locations/${id}`, {
//       title_en: values.title_en,
//       title_fr: values.title_fr,
//       web_link: values.website,
//       longitude:values.longitude,
//       latitude:values.latitude,
//       img:imageResponse,
//       address:values.address,
//       slug:slug,
//       code:countryCode,
//       number:phoneNumber

      
//     }).then((res) => {
//       data = {
//         data: res.data,
//         status: res.status,
//       };
//     })
//     .catch((e) => {
//       if (e.response) {
//         data = {
//           ...e.response.data,
//           status: e.response.status,
//         };
//       }
//     });
//   return data;
// };

// //Location - Delete CMS Location
// export const deleteLocation = async (id) => {
//   let data;
//   await apiHeader
//     .delete(`/locations/${id}`).then((res) => {
//       data = {
//         data: res.data,
//         status: res.status,
//       };
//     })
//     .catch((e) => {
//       if (e.response) {
//         data = {
//           ...e.response.data,
//           status: e.response.status,
//         };
//       }
//     });
//   return data;
// };

// //Location - Status Update CMS Location
// export const locationStatusUpdate = async (id, statusActive) => {
//   let data;
//   await apiHeader
//    .patch(`/locations/${id}/status`, {
//       status: statusActive,
//     })
//     .then((res) => {
//       data = {
//         data: res,
//       };
//     })
//     .catch((err) => {
//       if (err.res) {
//         data = {
//           ...err.res,
//         };
//       }
//     });
//   return data;
// };

// CMS - View Gallery Listing
export const getGallery = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/galleries?limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS - Create Gallery 
export const galleryCreate = async (values, imageResponse) => {
  let data;
  await apiHeader
    .post(`/galleries/`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      img: imageResponse,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS - Update Gallery
export const galleryUpdate = async (values, id, imageResponse) => {
  let data;
  await apiHeader
    .put(`/galleries/${id}`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      img: imageResponse,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS - Delete Location
export const deleteGallery = async (id) => {
  let data;
  await apiHeader
    .delete(`/galleries/${id}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS - Status Update Location
export const galleryStatusUpdate = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/galleries/${id}/status`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//CMS - Home- Get Testimonails
export const getTestimonials = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`cms/testimonials?limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS - Home Create Testimonials
export const testimonialsCreate = async (values) => {
  let data;
  await apiHeader
    .post(`cms/testimonials`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      description_en: values.description_en,
      description_fr: values.description_fr,
      img: values.image
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS - Home Update Testimonials
export const testimonialsUpdate = async (values,id) => {
  console.log(values);
  let data;
  await apiHeader
    .put(`cms/testimonials/${id}`, {
      title_en: values.title_en,
      title_fr: values.title_fr,
      description_en: values.description_en,
      description_fr: values.description_fr,
      img: values.image
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS - Home Delete Testimonials
export const deletetestimonials = async (id) => {
  let data;
  await apiHeader
    .delete(`/cms/testimonials/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS - Home Status Update Testimonials
export const testimonailsStatusUpdate = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/cms/testimonials/${id}`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//Swap Testimonials
export const testimonailsSwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch("/cms/testimonials/swap-order", {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS - Home Popular Art Work
export const getArtWork = async () => {
  let data;
  await apiHeader
    .get(`content/most-popular-art-work`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS - Update Home Popular Art Work
export const ArtWorkUpdate = async (values,imageResponse) => {
  let data;
  await apiHeader
    .put(`content/most-popular-art-work`, {
      title_en: values.artwork_title_en,
      title_fr: values.artwork_title_fr,
      description_en: values.detail_en,
      description_fr: values.detail_fr,
      img:imageResponse
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS - Home Boxes
export const getHomeBoxes = async () => {
  let data;
  await apiHeader
    .get(`content/art-strategic-event`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS - Home Boxes Update
export const HomeBoxesUpdate = async (values) => {
  let data;
  await apiHeader
    .put(`content/art-strategic-event`, {
      box1:values.box1,
      box2: values.box2,
      box3_usd: values.box3_usd,
      box3_euro: values.box3_euro,
      box4_usd:values.box4_usd,
      box4_euro: values.box4_euro,
      box5:values.box5,
      box6:values.box6,
      box7:values.box7,
      box8: values.box8,
      registrations: values.registrations,
      transactions: values.transactions,
      value_usd: values.average_basket_usd,
      value_euro: values.average_basket_euro,
      commissions: values.commissions,
      individuals: values.individuals,
      countries: values.countries,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Single Image Upload
export const singleImage = async (image) => {
  let data;
  const apiUrl = "/media/single-image-upload";
  const formData = new FormData();
  formData.append("image", image);

  try {
    const res = await apiHeader.post(apiUrl, formData);
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }

  return data;
};

//Multi Image Upload
export const multiImage = async (formData) => {
  let data;
  const apiUrl = "/media/multiple-image-upload";
  // const formData = new FormData();
  // formData.append("images", image);
    // Append each image to the FormData
    // images.forEach((image, index) => {
    //   formData.append(`images[${index}]`, image);
    // });

  try {
    const res = await apiHeader.post(apiUrl, formData,{ headers: { "Content-Type": "multipart/form-data" } });
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }

  return data;
};


// Site Settings - Footer
export const getSiteSettings = async () => {
  let data;
  await apiHeader
    .get("/content/site-setting")
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};



// Site Settings - Footer - Update
export const updateSiteSettings = async (values) => {
  let data;
  await apiHeader
    .put("/content/site-setting", {
      phone: values.phone.number,
      slug: values.phone.slug,
      country_code: values.phone.code,
      email: values.email,
      address: values.address,
      facebook: values.facebook,
      linkedin: values.linkedin,
      instagram: values.instagram,
      twitter: values.twitter,
      google: values.google,
      youtube: values.youtube,

    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Footer
export const getFooter = async (limit, offset) => {
  let data;
  await apiHeader
    .get(`content/footer?limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Footer Create
export const footerCreate = async (values,imageResponse) => {
  let data;
  await apiHeader
    .post(`/content/footer`, {
      img: imageResponse,
      link:values?.link
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Footer Update
export const footerUpdate = async (values,id,imageResponse) => {
  let data;
  await apiHeader
    .put(`/content/footer/${id}`, {
      img: imageResponse,
      link:values?.link
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Footer Delete
export const deleteFooter = async (id) => {
  let data;
  await apiHeader
    .delete(`/content/footer/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  Logs 
export const getLogs = async (limit, offset, search, from_date, to_date) => {
  let data;
  await apiHeader
    .get(`/activity-logs/?search=${search}&limit=${limit}&offset=${offset}&from_date=${from_date}&to_date=${to_date}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//  WaitList 
export const getWaitList = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/waitlist/?search=${search}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};