
import React from "react";
import './CustomPagination.css';
import { Button } from "antd";
import { UilAngleRight, UilAngleLeft } from "@iconscout/react-unicons";

function CustomPagination({tableData, limit, page, setPage}) {

  // Navigate to the previous page
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // Navigate to the next page
  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="pagination-buttons">
      <Button onClick={handlePreviousPage} disabled={page <= 1}>
        <UilAngleLeft />
      </Button>
      <Button onClick={handleNextPage} disabled={tableData?.length < limit }>
        <UilAngleRight />
      </Button>
    </div>
  );
}

export default CustomPagination;
