import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Tooltip,
  Modal,
  Image,
  notification,
  Input,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  UilPlus,
  UilSearch,
  UilEdit,
  UilTrash,
} from "@iconscout/react-unicons";
import { RecordViewWrapper } from "../../container/crud/axios/Style";
import { TableWrapper, Main } from "../../container/styled";
import { Button } from "../../components/buttons/buttons";
import { Cards } from "../../components/cards/frame/cards-frame";
import { PageHeader } from "../../components/page-headers/page-headers";
import { ExclamationCircleFilled } from "@ant-design/icons";
import CustomPagination from "../Pagination/CustomPagination";
import { deleteFooter, getFooter } from "../../utility/api";
const { confirm } = Modal;

function Footer() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [search, setSearch] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [baseURL,setBaseURL] = useState()

  useEffect(() => {
    getData();
  }, [page, limit]);

  // Get Data
  const getData = async (businessFilter) => {
    const offset = (page - 1) * limit;
    const result = await getFooter(limit, offset);
    const searchVal = businessFilter ? businessFilter : search;
    const { data, error, status } = result || {};
    if (status === 200) {
      setLoading(false)
      setDataCount(data?.footer || 0);
      setTableData(data?.footer|| []);
      setBaseURL(data?.base_url )

    } else {
      setLoading(false)
      notification.error({ message: error || 'Internal server error' })
    }
  };

  // Delete Record
  const handleDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleFilled />,
      content: id?.title?.en,
      okText: 'Yes',
      cancelText: 'No',
      maskClosable: true,
      centered: true,
      closable: true,
      async onOk() {
        try {
          setLoading(true);
          const result = await deleteFooter(id?._id); // Assuming deleteFaqs is the correct function
          const { status, error, success, data } = result || {};
          if (status === 200) {
            getData()
            notification.success({ message: data?.success });
          } else {
            notification.error({ message: error });
          }
        } catch (error) {
          console.log('Error during delete operation:', error);
          notification.error({ message: 'An error occurred during the delete operation.' });
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const Create = () => {
    const data ={
      heading: "Create Footer"
    };
    navigate("/admin/settings/footer/create", {
        state: data });
    };
  
  const Edit = (subscriber) => {
    const data = {
      link: subscriber?.link,
      img:subscriber?.img,
      heading: "Edit Footer",
      baseURL: baseURL,
      id:subscriber?._id
    };
    navigate("/admin/settings/footer/edit", { state: data });
  };
  // Search Filter
  const handleSearch = () => {
    setTableData([]);
    setPage(1);
    getData();
  };

  // Pagination
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Image View",
      dataIndex: "img",
      key: "img",
      align: "center",
      render: (img, record) => (
        <Image
          key={`img-${record?._id}`}
          width={40}
          height={40}
          style={{
            objectFit: "cover",
            display: "flex",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
          src={baseURL + img}
        />
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      // render: (link) => (<a href={link} target="_blank">{link}</a>)     
    },
    {
      title: "Action",
      dataIndex: "name",
      key: "action",
      width: "100px",
      align: "center",
      render: (_id, record) => (
        <div className="table-actions category-action-s" style={{ textAlign: "center" }}>
          <Button className="btn-add_new" size="default" key="1" type="primary" onClick={() => Edit(record)}>
            <Link className="edit" to="#">
              <UilEdit />
            </Link>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Link className="delete" onClick={() => handleDelete(record)} to="#">
            <UilTrash />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <RecordViewWrapper>
      <PageHeader
        className="ninjadash-page-header-main"
        buttons={[
          <Button
            className="btn-add_new"
            size="default"
            key="1"
            type="primary"
            onClick={() => Create()}
          >
            <Link to="#">
              <UilPlus /> <span>Create</span>
            </Link>
          </Button>,
        ]}
        ghost
        title="Footer"
      />

      <Main>
        <Row gutter={15}>
          <Col className="w-100" md={24}>
            <Cards headless>
              {isLoading ? (
                <div className="spin">
                  <Spin />
                </div>
              ) : (
                <div>
                  <TableWrapper className="table-data-view table-responsive">
                    <Table
                      pagination={false}
                      dataSource={tableData}
                      columns={columns}
                      rowKey="_id"
                      className="custom-tablecms"
                    />
                    <CustomPagination
                      tableData={tableData}
                      limit={limit}
                      page={page}
                      setPage={setPage}
                    />
                  </TableWrapper>
                </div>
              )}
            </Cards>
          </Col>
        </Row>
      </Main>
    </RecordViewWrapper>
  );
}

export default Footer;
