import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from '../SmartTrade/Auth/Index';

const ForgotPass = lazy(() => import('../SmartTrade/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../SmartTrade/Auth/ResetPassword'));
const OtpVerification = lazy(() => import('../SmartTrade/Auth/OtpVerification'));
const Login = lazy(() => import('../SmartTrade/Auth/SignIn'));
const SignUp = lazy(() => import('../SmartTrade/Auth/Signup'));
// const FbLogin = lazy(() => import('../profile/Auth/FbSignIn'));
// const FbSignUp = lazy(() => import('../profile/Auth/FbSignup'));
// const NotFound = lazy(() => import('../pages/404'));

const AuthRoot = () => {
  const navigate = useNavigate();

  useEffect(() => navigate('/'));
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="forgot" element={<ForgotPass />} />
      <Route path="otp-verification" element={<OtpVerification />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="register" element={<SignUp />} />
      {/* <Route path="fbRegister" element={<FbSignUp />} /> */}
      {/* <Route path="fbSignIn" element={<FbLogin />} /> */}
      {/* <Route path="*" element={<NotFound />} /> */}
      <Route path="*" element={<AuthRoot />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
