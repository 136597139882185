import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import Footer from '../../SmartTrade/Settings/Footer';


// Painting
const Painting = lazy(() => import('../../SmartTrade/Paintings/Painting/Painting'));
const PaintingDetails = lazy(() => import('../../SmartTrade/Paintings/Painting/PaintingDetails'));
const AddPainting = lazy(() => import('../../SmartTrade/Paintings/Painting/AddPainting'));
const UnlistedPainting = lazy(() => import('../../SmartTrade/Paintings/UnlistedPainting/UnlistedPainting'));
const UnlistedEdit = lazy(() => import('../../SmartTrade/Paintings/UnlistedPainting/UnlistedEdit'));

// Primary Market
const PrimaryMarket = lazy(() => import('../../SmartTrade/PrimaryMarket/PrimaryMarket'));
const PrimaryMarketDetails = lazy(() => import('../../SmartTrade/PrimaryMarket/PrimaryMarketDetails'));

// Secondary Market
const SecondaryMarket = lazy(() => import('../../SmartTrade/SecondaryMarket/SecondaryMarket'));
const SecondaryMarketEdit = lazy(() => import('../../SmartTrade/SecondaryMarket/SecondaryMarketEdit'));

// Dividend
const Dividend = lazy(() => import('../../SmartTrade/Dividend/Dividend'));
const AddDividend = lazy(() => import('../../SmartTrade/Dividend/CreateDividend'));
const DividendDetails = lazy(() => import('../../SmartTrade/Dividend/DividendDetails'));

// Smart TradeUsers
const RegisteredUsers = lazy(() => import('../../SmartTrade/Users/RegisteredUsers'));
const RegisteredUsersDetails = lazy(() => import('../../SmartTrade/Users/RegisteredUsersDetails'));
const Artists = lazy(() => import('../../SmartTrade/ManageArtists/Artists'));
// const ArtistsDetails = lazy(() => import('../../SmartTrade/ManageArtists/ArtistsDetails'));
const CreateArtists = lazy(() => import('../../SmartTrade/ManageArtists/CreateArtists'));
const EditArtists = lazy(() => import('../../SmartTrade/ManageArtists/EditArtists'));


// Payouts
const PendingPayouts = lazy(() => import('../../SmartTrade/Payouts/PendingPayouts/PendingPayouts'));
const PaidPayouts = lazy(() => import('../../SmartTrade/Payouts/PaidPayouts/PaidPayouts'));

//CMS
const ManageHome = lazy(() => import('../../SmartTrade/CMS/ManageHome/ManageHome'))
const CreateTestimonials = lazy(() => import('../../SmartTrade/CMS/ManageHome/Testimonials/CreateTestimonials'))
const Gallery = lazy(() => import('../../SmartTrade/CMS/Gallery/ViewGallery'))
const CreateGallery = lazy(() => import('../../SmartTrade/CMS/Gallery/CreateGallery'))
// const Locations = lazy(() => import('../../SmartTrade/CMS/Locations/Locations'))
// const CreateLocation = lazy(() => import('../../SmartTrade/CMS/Locations/CreateLocation'))
const TermsConditions = lazy(() => import('../../SmartTrade/CMS/TermsConditions/TermsConditions'))
const CreateTermsConditions = lazy(() => import('../../SmartTrade/CMS/TermsConditions/CreateTermsConditions'))
const PrivacyPolicy = lazy(() => import('../../SmartTrade/CMS/PrivacyPolicy/PrivacyPolicy'))
const FAQs = lazy(() => import('../../SmartTrade/CMS/FAQs/FAQs'))

// Settings
const EditProfile = lazy(() => import('../../SmartTrade/Settings/EditProfile'));
const ChangePassword = lazy(() => import('../../SmartTrade/Settings/ChangePassword'));
const SiteSettings = lazy(() => import('../../SmartTrade/Settings/SiteSettings'));
const CreateFooter = lazy(() => import('../../SmartTrade/Settings/CreateFooter'));
const WaitList = lazy(() => import('../../SmartTrade/Settings/WaitList/WaitList'));

// Logs
const Logs = lazy(() => import('../../SmartTrade/Logs/Logs'));

// Not Found
const NotFound = lazy(() => import('../../container/pages/404'));

// Coming Soon
const ComingSoon = lazy(() => import('../../container/pages/ComingSoon'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>

        {/*Dashboard */}
        <Route path="admin/dashboard" element={<ComingSoon />} />

        {/* Painting */}
        <Route path="admin/paintings" element={<Painting />} />
        <Route path="admin/paintings/details" element={<PaintingDetails />} />
        <Route path="admin/paintings/add" element={<AddPainting />} />
        <Route path="admin/unlisted-paintings" element={<UnlistedPainting />} />
        <Route path="admin/unlisted-paintings/edit" element={<UnlistedEdit />} />

        {/* Primary Market */}
        <Route path="admin/primary-market" element={<PrimaryMarket />} />
        <Route path="admin/primary-market/details" element={<PrimaryMarketDetails />} />

        {/* Secondary Market */}
        <Route path="admin/secondary-market" element={<SecondaryMarket />} />
        <Route path="admin/secondary-market/edit" element={<SecondaryMarketEdit />} />

        {/* Dividend */}
        <Route path="admin/dividend" element={<Dividend />} />
        <Route path="admin/add-dividend" element={<AddDividend />} />
        <Route path="admin/dividend/details" element={<DividendDetails />} />
      
        {/* Payouts */}
        <Route path="admin/payout/pending-payouts" element={<PendingPayouts />} />
        <Route path="admin/payout/paid-payouts" element={<PaidPayouts />} />

        {/* Smart TradeUsers */}
        <Route path="admin/artists" element={<Artists />} />
        <Route path="admin/artist/create" element={<CreateArtists />} />
        <Route path="admin/artist/edit" element={<EditArtists />} />
        {/* <Route path="admin/artists-details" element={<ArtistsDetails />} /> */}
        <Route path="admin/users" element={<RegisteredUsers />} />
        <Route path="admin/users/details" element={<RegisteredUsersDetails />} />

        {/* CMS */}
        <Route path="admin/cms/manage-home" element={<ManageHome />} />
        <Route path="admin/cms/testimonials/create" element={<CreateTestimonials />} />
        <Route path="admin/cms/testimonials/edit" element={<CreateTestimonials />} />
        <Route path="admin/cms/terms" element={<TermsConditions />} />
        <Route path="admin/cms/terms/create" element={<CreateTermsConditions />} />
        <Route path="admin/cms/terms/edit" element={<CreateTermsConditions />} />
        <Route path="admin/cms/privacy-policy/create" element={<CreateTermsConditions />} />
        <Route path="admin/cms/privacy-policy/edit" element={<CreateTermsConditions />} />
        <Route path="admin/cms/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="admin/cms/gallery" element={<Gallery />} />
        <Route path="admin/cms/gallery/edit" element={<CreateGallery />} />
        <Route path="admin/cms/gallery/create" element={<CreateGallery />} />
        {/* <Route path="admin/cms/locations" element={<Locations />} />
        <Route path="admin/cms/locations/edit" element={<CreateLocation />} />
        <Route path="admin/cms/locations/create" element={<CreateLocation />} /> */}
        <Route path="admin/cms/faqs/create" element={<CreateTermsConditions />} />
        <Route path="admin/cms/faqs/edit" element={<CreateTermsConditions />} />
        <Route path="admin/cms/faqs" element={<FAQs />} />

        {/* Settings */}
        <Route path="admin/settings/edit-profile" element={<EditProfile />} />
        <Route path="admin/settings/change-password" element={<ChangePassword />} />
        <Route path="admin/settings/site-settings" element={<SiteSettings />} />
        <Route path="admin/settings/footer" element={<Footer />} />
        <Route path="admin/settings/footer/create" element={<CreateFooter />} />
        <Route path="admin/settings/footer/edit" element={<CreateFooter />} />
        <Route path="admin/settings/waitlist" element={<WaitList />} />

        {/* Logs */}
        <Route path="admin/logs" element={<Logs />} />

        {/* Not Found */}
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="*" element={<Navigate to="/admin/dashboard" />} />

      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
