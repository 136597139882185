/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import React, { useState, useEffect } from "react";
import { Upload, message, Modal, Image } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const ellipsis = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length
    ? `${"0".repeat(length - number.length)}${number}`
    : number;
};

const filteredPaths = () => {
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const paths = userData?.permissions?.filter((path) => {
    if (path.endsWith("/store")) {
      return path.endsWith("/create");
    } else if (path.endsWith("/update-status") || path.endsWith("/update")) {
      return path.endsWith("/edit");
    }
    return true;
  });

  return paths;
};

// Image Upload
// const ImageUploader = ({ imageUrl, handleChange, form, page }) => {
//   const [loadingImg, setLoadingImg] = useState(false);
//   const multiImage = page == "addPainting" ? true : false;

//   // Image Uploader
//   const getBase64 = (img, callback) => {
//     const reader = new FileReader();
//     reader.addEventListener("load", () => callback(reader.result, img));
//     reader.readAsDataURL(img);
//   };

//   // Before Image Upload
//   const beforeUpload = (file) => {
//     if (file) {
//       const isJpgOrPng =
//         file.type === "image/jpeg" || file.type === "image/png";
//       if (!isJpgOrPng) {
//         message.error("You can only upload JPG/PNG file!");
//       }
//       const isLt2M = file.size / 1024 / 1024 < 5;
//       if (!isLt2M) {
//         message.error("Image must smaller than 5MB!");
//       }
//       return isJpgOrPng && isLt2M;
//     }
//   };

//   // Handle Image Change
//   const handleImageChange = (info) => {
//     if (info.file.status === "uploading") {
//       setLoadingImg(true);
//       return;
//     }
//     if (info.file.status === "done") {
//       getBase64(info?.file?.originFileObj, (url, imageObj) => {
//         setLoadingImg(false);
//         handleChange(url, imageObj);
//       });
//     }
//     if (info.file.status === "error") {
//       setLoadingImg(false);
//       handleChange(null, null);
//       form.setFields([
//         { name: multiImage ? "multi_image" : "image", value: null },
//         {
//           name: multiImage ? "multi_image" : "image",
//           errors: ["Image is not compatible."],
//         },
//       ]);
//     }
//   };

//   const uploadButton = (
//     <div>
//       {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
//       <div style={{ marginTop: 8 }}> Upload </div>
//     </div>
//   );

//   console.log(imageUrl);
//   return (
//     <>
//       <Upload
//         name="image"
//         listType="picture-card"
//         className="avatar-uploader"
//         showUploadList={multiImage}
//         multiple={multiImage}
//         maxCount={multiImage ? 10 : 1}
//         accept=".jpg, .jpeg, .png"
//         // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
//         // action="https://run.mocky.io/v3/3eaa9b5f-bf0a-4454-ac52-2a3e051e1a20"
//         // beforeUpload={beforeUpload}
//         onChange={handleImageChange}
//       >
//         {!multiImage && imageUrl ? (
//           <img src={imageUrl} alt="image" style={{ width: "100%" }} />
//         ) : (
//           uploadButton
//         )}
//       </Upload>
//     </>
//   );
// };

// Image Upload
const ImageUploader = ({
  imageUrl,
  handleChange,
  form,
  page,
  editable,
  setImg
}) => {
  const data = localStorage.getItem("token") || "";
  const multiImage = page == "addPainting" ? true : false;
  const domain = process.env.REACT_APP_BASEURL + "/";
  const [fileList, setFileList] = useState([]);

  const fileUploadPropss = {
    action: process.env.REACT_APP_BASEURL + "/media/single-image-upload",
    name: "file",
    method: "POST",
    headers: {
      Authorization: `Bearer ${data}`,
      ContentType: "multipart/form-data",
    },
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5; // Checking file size is less than 5MB
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return false;
      }
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
        return false;
      }
      return true;
    },
  };

  useEffect(() => {
    if (editable) {
      if (imageUrl && imageUrl?.length > 0) {
        const checkArrary = Array.isArray(imageUrl) ? imageUrl : [imageUrl];
        const fileListData = checkArrary?.map((url, index) => ({
          uid: index,
          name: `image-${index + 1}`,
          status: "done",
          url: process.env.REACT_APP_BASEURL + "/" + url,
        }));
        setFileList(fileListData);
      }
    }
  }, [imageUrl]);

  const onChange = ({ fileList }) => {
  
    // Check if the file size is less than 5 MB (5 MB)    
    const list = fileList?.filter(data => {
      return !data?.size || data?.size / 1024 / 1024 < 5;
    });  
    setFileList(list);
    // Check if the status is done and urls are present
    const urls = fileList?.map((data) => {
      if (data.status == "done") {
        return data?.response || data?.url?.substring(domain?.length);
      }
    });
    // Handle Image Upload For Edit Unlisted Paintings
    if (handleChange) handleChange(urls);
    if (setImg) setImg(urls);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <div>
      <Upload
        {...fileUploadPropss}
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        showUploadList={true}
        multiple={multiImage ? true : false}
        name="image"
        accept=".jpg, .jpeg, .png"
        maxCount={multiImage ? 10 : 1}
      >
        {multiImage
          ? fileList.length < 10 && uploadButton
          : fileList.length < 1 && uploadButton}
      </Upload>
    </div>
  );
};


// Artist Create and Artists Edit Image Upload

const ArtistsImageUploader = ({ imageUrl, handleChange, form, page }) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const multiImage = page == "addPainting" ? true : false;

  // Image Uploader
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result, img));
    reader.readAsDataURL(img);
  };

  // Before Image Upload
  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    }
  };
  // Handle Image Change
  const handleImageChange = (info) => {
    if (info.file && info.file.originFileObj instanceof Blob) {
      getBase64(info.file.originFileObj, (base64) => {
        setLoadingImg(false);
        handleChange(info.file.originFileObj, base64);
      });
    } else {
      setLoadingImg(false);
      handleChange(null, null);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <>
      <Upload
        name="image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={multiImage}
        multiple={multiImage}
        maxCount={multiImage ? 10 : 1}
        accept=".jpg, .jpeg, .png"
        beforeUpload={beforeUpload}
        onChange={handleImageChange}
        customRequest={() => {}} 
      >
        {!multiImage && imageUrl ? (
          <img src={imageUrl} alt="image" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};
// const ArtistsImageUploader = ({
//   imageUrl,
//   handleChange,
//   form,
//   page,
//   editable,
//   setImg
// }) => {
//   const data = localStorage.getItem("token") || "";
//   const multiImage = page == "addPainting" ? true : false;
//   const domain = process.env.REACT_APP_BASEURL + "/";
//   const [fileList, setFileList] = useState([]);

//   const fileUploadPropss = {
//     action: process.env.REACT_APP_BASEURL + "/media/single-image-upload",
//     name: "file",
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${data}`,
//       ContentType: "multipart/form-data",
//     },
//     beforeUpload: (file) => {
//       const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//       const isLt5M = file.size / 1024 / 1024 < 5; // Checking file size is less than 5MB
//       if (!isJpgOrPng) {
//         message.error("You can only upload JPG/PNG file!");
//         return false;
//       }
//       if (!isLt5M) {
//         message.error("Image must be smaller than 5MB!");
//         return false;
//       }
//       return true;
//     },
//   };

//   useEffect(() => {
//     if (editable) {
//       if (imageUrl && imageUrl?.length > 0) {
//         const checkArrary = Array.isArray(imageUrl) ? imageUrl : [imageUrl];
//         const fileListData = checkArrary?.map((url, index) => ({
//           uid: index,
//           name: `image-${index + 1}`,
//           status: "done",
//           url: process.env.REACT_APP_BASEURL + "/" + url,
//         }));
//         setFileList(fileListData);
//       }
//     }
//   }, [imageUrl]);

//   const onChange = ({ fileList }) => {
  
//     // Check if the file size is less than 5 MB (5 MB)    
//     const list = fileList?.filter(data => {
//       return !data?.size || data?.size / 1024 / 1024 < 5;
//     });  
//     setFileList(list);
//     // Check if the status is done and urls are present
//     const urls = fileList?.map((data) => {
//       if (data.status == "done") {
//         return data?.response || data?.url?.substring(domain?.length);
//       }
//     });
//     // Handle Image Upload For Edit Unlisted Paintings
//     if (handleChange) handleChange(urls);
//     if (setImg) setImg(urls);
//   };

//   const uploadButton = (
//     <div>
//       <PlusOutlined />
//       <div style={{ marginTop: 8 }}> Upload </div>
//     </div>
//   );

//   return (
//     <div>
//       <Upload
//         {...fileUploadPropss}
//         listType="picture-card"
//         fileList={fileList}
//         onChange={onChange}
//         showUploadList={true}
//         multiple={multiImage ? true : false}
//         name="image"
//         accept=".jpg, .jpeg, .png"
//         maxCount={multiImage ? 10 : 1}
//       >
//         {multiImage
//           ? fileList.length < 10 && uploadButton
//           : fileList.length < 1 && uploadButton}
//       </Upload>
//     </div>
//   );
// };
// Styled Phone
const StyledPhoneInput = ({ phoneNum, setPhoneNum, form, setCountryCode }) => {
  const onChange = (value, country) => {
    setCountryCode(country.dialCode);
    setPhoneNum(value);
    form.validateFields(["phone"]);
  };
  return (
    <PhoneInput
      defaultCountry="uae"
      country="uae"
      countryCodeEditable={false}
      inputProps={{
        style: { width: "100%", height: "50px" },
      }}
      value={phoneNum}
      onChange={(value, country) => onChange(value, country)}
      validateStatus={form.getFieldError("phone") ? "error" : ""}
      help={form.getFieldError("phone")?.[0]}
    />
  );
};

// Get User Data From Local Storage
const userData = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  return userData;
};


// Cover And Multi Image Preview
const ImagePreview = ({imageUrl}) => {
  return (
    <div className="d-flex align-items-center">
    { Array.isArray(imageUrl) ?
      imageUrl?.map((data, i) => {
        return (
          <div 
            className="multi-image-preview">
            <Image
              key={i}
              src={process.env.REACT_APP_BASEURL +"/"+ data}
            />
           </div>
        )})
        :
        <div className="multi-image-preview">
          <Image
            src={process.env.REACT_APP_BASEURL +"/"+ imageUrl}
          />
        </div>
        }
      </div>
    );
  };

  
  // Get Remember Email for LogIn
  const remEmail = (form, setRemMe) => {
    const remMe = localStorage.getItem("rememberedEmail");
    if (remMe) {
      form.setFieldsValue({
        email: remMe
      })
      setRemMe(true);
    }
  }

 // Primary Market - Purchase Modal - Validate Total Parts for  
  const validateTotalPart = (value, remaining_part) => {
    const intValue = parseInt(value);
    if (isNaN(intValue)) {
      return Promise.reject("You cannot leave this field blank.");
    }
    else if (intValue <= 0) {
      return Promise.reject("Parts must be greater than zero.");
    }
    else if (intValue > remaining_part) {
      return Promise.reject(`Parts cannot exceed more then ${remaining_part}.`);
    }
    else if (value.includes('.')) {
      return Promise.reject("Decimal values are not allowed");
    }
    return Promise.resolve();
  };


  // Add Painting - Unlisted Painting - Home (Strategic Investment) field validator
  const validateZero = (rule, value, callback) => {
    if (!value) {
      callback("You cannot leave this field blank");
    } else {
      const numericValue = parseFloat(value);
      if (isNaN(numericValue) || numericValue < 1) {
        callback("Value should be greater than zero");
      } else {
        callback();
      }
    }
  };

  
  // Add Painting - Unlisted Painting -  field validator
  const validateInteger = (_, value) => {
    if (!value) {
      return Promise.reject('You cannot leave this field blank');
    }
    const numericValue = parseFloat(value);
    if (isNaN(numericValue) || numericValue < 1) {
      return Promise.reject('Value should be greater than zero');
    }
    if (!Number.isInteger(numericValue)) {
      return Promise.reject('Value should be an integer');
    }
    return Promise.resolve();
  };  

export {
  ImageUploader,
  ellipsis,
  idGenerator,
  filteredPaths,
  // ImageUploader,
  StyledPhoneInput,
  userData,
  ArtistsImageUploader,
  ImagePreview,
  remEmail,
  validateTotalPart,
  validateZero,
  validateInteger
};
